import { generateMailtoLink } from 'app-sections/user/applications/steps/components/swo-wrapper/SwoInfo';
import dayjs from 'dayjs';
import { Application, Params, Review, UserData } from 'types';
import { isAdmin, isClient } from 'ui/helpers/users';

import { Box } from '@mui/material';

import { DOB_CONTACT_EMAIL } from '../config';
import { getDivisionNameById } from '../details/components/sections/status-info/components/divisions-info/helpers';

const DAYS_AFTER_LIFTED = 3;

function getSWOInfoContent(user: UserData | null, application: Application): Params {
  if (isAdmin(user?.userProfile.roles || [])) {
    return {
      title: 'This application is on hold',
      content: (
        <>
          A Hold/Lock restriction has been placed on this address or on an associated permit.
          <br />
          The Review Process will be halted until restriction is lifted. Restriction(s) description:
          <br />
          {application.swoConditions?.join('; ')}
        </>
      ),
      status: 'failed',
    };
  }

  return {
    title: 'This application is on hold',
    content: (
      <>
        We would like to inform you that a HOLD restriction has been placed on the address, parcel or associated
        permit(s) linked to this application. This restriction will halt the application review process until it is
        lifted, at which point you will be notified directly. Restriction description:
        <br />
        {application.swoConditions?.join('; ')}
        <br />
        For further information and next steps, please contact{' '}
        <strong>
          <a href={generateMailtoLink(application)}>DOB at {DOB_CONTACT_EMAIL}</a>
        </strong>{' '}
        or{' '}
        <Box component="strong" sx={{ whiteSpace: 'nowrap' }}>
          202-671-3500
        </Box>
        .
      </>
    ),
    status: 'failed',
  };
}

function getSWOLiftedInfoContent(
  user: UserData | null,
  application: Application,
  reviewsData: Review[] | undefined
): Params | null {
  if (!isClient(user?.userProfile.roles || [])) {
    return null;
  }

  // check if some of the reviews has `inspectionsState=client_action_needed`
  const clientActionNeeded = reviewsData?.some((r) => r.inspectionsState === 'client_action_needed');

  // if clientActionNeeded is not true, check if passed 3 days after swoLiftedAt with dayjs
  if (!clientActionNeeded) {
    if (checkIsLiftedAfter(application.swoLiftedAt || '')) {
      return null;
    }
  }

  return {
    title: 'Hold/Lock Restriction Lifted',
    content: (
      <>
        We would like to inform you that the Hold/Lock restriction associated with this application has been lifted.
        {clientActionNeeded && (
          <>
            <br />
            Due to the Hold restrictions, previously scheduled inspection was canceled. <br />
            <strong>Please reschedule the canceled inspection.</strong>
          </>
        )}
      </>
    ),
    status: 'success',
  };
}

export function getInfoContent(
  data: Application,
  reviewsData: Review[] | undefined,
  user: UserData | null
): Params | null {
  if (data.state === 'denied') {
    return {
      title: 'This application is denied',
      content: getReasonDataFromReviews(reviewsData),
      status: 'failed',
    };
  }

  if (data.state === 'deleted_by_client') {
    return {
      title: 'This application is canceled by client',
      content: 'Interaction with this application is no longer available.',
      status: 'failed',
    };
  }

  if (data.swoLiftedAt) {
    return getSWOLiftedInfoContent(user, data, reviewsData);
  }

  // SWO
  return getSWOInfoContent(user, data);
}

function getReasonDataFromReviews(reviews: Review[] | undefined): JSX.Element {
  if (!reviews) {
    return (
      <>
        During the review process this application was denied by one of the reviewers. Interaction with this application
        is no longer available.
      </>
    );
  }

  // find denied review
  const denied = reviews.find((r) => r.state === 'rejected');

  if (!denied) {
    return <></>;
  }

  return (
    <>
      During the review process this application was denied by one of the reviewers. Interaction with this application
      is no longer available.
      <br />
      <br />
      Reviewer (Division) -{' '}
      <strong>
        {denied?.reviewerProfile.fullName || ''} ({getDivisionNameById(denied.divisionName)})
      </strong>
      <br />
      Denial reason: <br />
      <Box>
        <strong>{denied.rejectionReason || ''}</strong>
      </Box>
    </>
  );
}

export function checkIsLiftedAfter(swoLiftedAt: string): boolean {
  if (!swoLiftedAt) {
    return true;
  }
  const swoLiftedAtDate = dayjs(swoLiftedAt);
  const now = dayjs();
  const diff = now.diff(swoLiftedAtDate, 'days');
  return diff > DAYS_AFTER_LIFTED;
}
