import { getApplicationById } from 'core/api/applications';
import { useApplicationsStore } from 'core/store/applications';
import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import SwoInfo from './SwoInfo';
import SwoLiftedInfo from './SwoLiftedInfo';
import { ApplicationConditionsInfo } from 'types';

export default function SwoWrapper() {
  const { activeApplicationId, hasSwoError, hasSwoLifted, setSwoError, setSwoLifted } = useApplicationsStore();

  const applicationQueryKey = ['applications', activeApplicationId];

  const { isSuccess, data } = useQuery({
    queryKey: applicationQueryKey,
    queryFn: () => getApplicationById(activeApplicationId),
    enabled: activeApplicationId !== '',
  });

  useEffect(() => {
    if (isSuccess && data) {
      setSwoError(data.swoConditions);
      setSwoLifted(Boolean(data?.swoLiftedAt));
    }
  }, [isSuccess]);

  if (hasSwoError && hasSwoError.length > 0) {
    return <SwoInfo application={data} data={data as ApplicationConditionsInfo} />;
  }

  if (hasSwoLifted) {
    return <SwoLiftedInfo data={data} />;
  }
  return null;
}
