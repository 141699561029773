import { create } from 'zustand';

export interface ApplicationsStore {
  activeStep: number;
  latestValidStep: number;
  activeApplicationId: string;
  hasSwoError: string[] | null;
  hasSwoLifted: boolean;
  crAuthorName: string;
  setActiveStep: (activeStep: number) => void;
  setLatestValidStep: (activeStep: number) => void;
  setActiveApplicationId: (id: string) => void;
  setSwoError: (hasSwoError: string[] | null) => void;
  setSwoLifted: (hasSwoLifted: boolean) => void;
  setCrAuthorName: (crAuthorName: string) => void;
}

export const useApplicationsStore = create<ApplicationsStore>((set) => ({
  activeStep: 0,
  latestValidStep: 0,
  activeApplicationId: '',
  hasSwoError: null,
  hasSwoLifted: false,
  crAuthorName: '',
  setActiveStep: (activeStep: number) => set(() => ({ activeStep })),
  setLatestValidStep: (latestValidStep: number) => set(() => ({ latestValidStep })),
  setActiveApplicationId: (activeApplicationId: string) => set(() => ({ activeApplicationId })),
  setSwoError: (hasSwoError: string[] | null) => set(() => ({ hasSwoError })),
  setSwoLifted: (hasSwoLifted: boolean) => set(() => ({ hasSwoLifted })),
  setCrAuthorName: (crAuthorName: string) => set(() => ({ crAuthorName })),
}));
