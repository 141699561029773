import { getApplicationById } from 'core/api/applications';
import { getReviews } from 'core/api/reviews';
import { useApplicationsStore } from 'core/store/applications';
import { useAuthStore } from 'core/store/auth';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Params, Review } from 'types';
import StatusCard from 'ui/components/status-card/StatusCard';

import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getInfoContent } from './messages';

const WARNING_STATES = ['denied', 'deleted_by_client', 'swo'];

export default function ApplicationStateListener() {
  const { id: activeApplicationId = '' } = useParams();
  const applicationQueryKey = ['applications', activeApplicationId];
  const { setSwoError } = useApplicationsStore();
  const { currentUser } = useAuthStore();

  const [showStateInfo, setShowStateInfo] = useState<Params | null>(null);
  const [reviewsNeeded, setReviewsNeeded] = useState<boolean>(false);

  const { data, isSuccess } = useQuery({
    queryKey: applicationQueryKey,
    queryFn: () => getApplicationById(activeApplicationId),
    enabled: activeApplicationId !== '',
  });

  // GET REVIEWS
  const queryKey = ['applications', data?.id || '', 'reviews'];
  const { data: reviewsData, isSuccess: isReviewsSuccess } = useQuery({
    enabled: data?.id !== '' && Boolean(data?.id) && reviewsNeeded,
    queryKey: queryKey,
    queryFn: () => getReviews<Review[]>(data?.id || ''),
  });

  useEffect(() => {
    if (data && isSuccess) {
      const areReviewsNeeded = data.state === 'denied' || data.swoLiftedAt;
      const isStateNotWarning = !WARNING_STATES.includes(data.state) && !data.swoLiftedAt;

      if (areReviewsNeeded) {
        setReviewsNeeded(true);
      } else {
        setReviewsNeeded(false);
      }

      if ((!reviewsData && areReviewsNeeded) || isStateNotWarning) {
        setShowStateInfo(null);
      } else {
        setShowStateInfo(getInfoContent(data, reviewsData, currentUser));
      }

      if (data.state === 'swo') {
        setSwoError(data.swoConditions);
      } else {
        setSwoError(null);
      }
    }
  }, [isSuccess, isReviewsSuccess, data?.state]);

  if (!showStateInfo) {
    return null;
  }

  return (
    <Box mt={5}>
      <StatusCard
        title={showStateInfo.title}
        content={showStateInfo.content}
        isLoading={false}
        status={showStateInfo.status || 'failed'}
      />
    </Box>
  );
}
