import { ApplicationState } from 'types';

export const APPLICATION_EDITABLE_STATES: ApplicationState[] = [
  'in_review',
  'ready_for_issuance',
  'client_action_needed',
  'certificate_draft_issued',
  'pending_payment',
];

export const REVIEWERS_MANAGEMENT_EDITABLE_STATES: ApplicationState[] = [...APPLICATION_EDITABLE_STATES, 'completed'];

export const CORRECTION_REQUESTS_EDITABLE_STATES: ApplicationState[] = [
  'in_review',
  'ready_for_issuance',
  'client_action_needed',
  'certificate_draft_issued',
];

export const DOB_CONTACT_EMAIL = 'dob@dc.gov';
