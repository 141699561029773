import { ApplicationPaymentState, ApplicationState } from 'types';

const STATE_BADGE_CLIENT: Record<ApplicationState, string> = {
  draft: "Draft",
  deleted_by_client: "Cancelled",
  in_review: "Review in Progress",
  ready_for_issuance: "Draft Issuance in Progress",
  client_action_needed: "Hold for correction",
  certificate_draft_issued: "Waiting on Client Approval",
  pending_payment: "Pending Payment",
  completed: "Issued",
  swo: "Hold or Lock Restriction",
  denied: "Denied",
};

const STATE_BADGE_ADMIN: Record<ApplicationState, string> = {
  draft: "Draft",
  deleted_by_client: "Cancelled",
  in_review: "Review in Progress",
  ready_for_issuance: "Draft Issuance in Progress",
  client_action_needed: "Hold for correction",
  certificate_draft_issued: "Waiting on Client Approval",
  pending_payment: "Pending Payment",
  completed: "Issued",
  swo: "Hold or Lock Restriction",
  denied: "Denied",
};

const PAYMENT_STATES: Record<ApplicationPaymentState, string> = {
  failed: "Failed",
  paid: "Paid",
  pending: "Pending",
};

export const getOverallStateBadgeLabel = (state: ApplicationState): string => {
  if (state === "draft") {
    return "Draft";
  } else if (
    [
      "draft_deleted_by_client",
      "deleted_by_client",
      "completed",
      "denied",
    ].includes(state)
  ) {
    return "Closed";
  } else {
    return "In Progress";
  }
};

export const getStateBadgeLabel = (
  type: "client" | "admin",
  state: ApplicationState
): string => {
  return type === "client"
    ? STATE_BADGE_CLIENT[state]
    : STATE_BADGE_ADMIN[state];
};

export const getPaymentStateLabel = (
  state: ApplicationPaymentState
): string => {
  return PAYMENT_STATES[state];
};
