import { DOB_CONTACT_EMAIL } from 'app-sections/common/applications/config';
import { Application, ApplicationConditionsInfo } from 'types';
import InfoMessage from 'ui/components/info-message/InfoMessage';

export function generateMailtoLink(data: Application | undefined): string {
  if (!data) {
    return `mailto:${DOB_CONTACT_EMAIL}?subject=SWO`;
  }

  const fullAddress = `Hold or Lock Restriction ${data.propertyAddressString}`;
  const encodedAddress = encodeURIComponent(fullAddress);
  return `mailto:${DOB_CONTACT_EMAIL}?subject=${encodedAddress}`;
}

export interface SwoInfoProps {
  data: ApplicationConditionsInfo | null;
  application: Application | undefined;
}

export default function SwoInfo(props: SwoInfoProps) {
  const { data, application } = props;
  return (
    <InfoMessage
      type="warning"
      notClosable={true}
      title="There is a Hold/Lock restriction in the address"
      content={
        <>
          <p>
            A hold or lock restriction has been placed on this address or on an associated permit. The certificate of
            occupancy application cannot be submitted until the restriction is lifted. Restriction description:{' '}
            {data?.swoConditions?.join(', ')}
            <br />
            Contact{' '}
            <strong>
              <a href={generateMailtoLink(application)}>DOB at {DOB_CONTACT_EMAIL}</a>
            </strong>{' '}
            to obtain information regarding the restriction and how to resolve hold or lock.
          </p>
          <p>
            You may continue the application process, but it will remain in draft status and cannot be submitted until
            the restriction has been resolved. At that time, you will receive notification and you may proceed with your
            application.
          </p>
        </>
      }
    />
  );
}
